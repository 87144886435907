import React, { useEffect, useState } from "react";
import axios from "axios";
import { Admin_CRUD_SUBSCRIPTION_URL } from "../../hooks/APIHooks";
import { useAuth } from "../../hooks/GlobalProvider";
import { Link } from "react-router-dom";
import { RiArrowGoBackFill, RiStore3Fill } from "react-icons/ri";

const SubscriptionList = () => {
  const { user } = useAuth();
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);

  // Filters
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (user && user.role === "admin") {
      axios
        .get(Admin_CRUD_SUBSCRIPTION_URL)
        .then((response) => {
          setSubscriptions(response.data);
          setFilteredSubscriptions(response.data);
          setLoading(false);
        })
        .catch(() => {
          setError("Failed to fetch subscriptions");
          setLoading(false);
        });
    } else {
      setError("Unauthorized access");
      setLoading(false);
    }
  }, [user]);

  // Handle Status Filter
  useEffect(() => {
    applyFilters();
  }, [statusFilter, startDate, endDate, subscriptions]);

  const applyFilters = () => {
    let filtered = subscriptions;

    // Status Filter
    if (statusFilter !== "ALL") {
      filtered = filtered.filter(
        (sub) => sub.status.toUpperCase() === statusFilter
      );
    }

    // Date Range Filter
    if (startDate) {
      filtered = filtered.filter(
        (sub) => new Date(sub.start_date) >= new Date(startDate)
      );
    }
    if (endDate) {
      filtered = filtered.filter(
        (sub) => new Date(sub.end_date) <= new Date(endDate)
      );
    }

    setFilteredSubscriptions(filtered);
  };

  const handleDeleteClick = (subscriptionId) => {
    if (window.confirm("Are you sure you want to delete this subscription?")) {
      axios
        .delete(`${Admin_CRUD_SUBSCRIPTION_URL}?id=${subscriptionId}`)
        .then(() => {
          setSubscriptions((prev) =>
            prev.filter((sub) => sub.id !== subscriptionId)
          );
        })
        .catch(() => {
          setDeleteError("Failed to delete the subscription.");
        });
    }
  };

  if (loading) {
    return <div className="text-center text-lg mt-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-lg text-red-500 mt-10">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4 mt-10">
      <h1 className="text-xl font-bold mb-6 text-center">Subscription List</h1>

      {/* Filters */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-6 gap-4">
        {/* Status Filter */}
        <div className="flex flex-wrap gap-2">
          {["ALL", "ACTIVE", "REVOKED", "CANCELED"].map((status) => (
            <button
              key={status}
              className={`px-4 py-2 text-sm rounded ${
                statusFilter === status
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => setStatusFilter(status)}
            >
              {status}
            </button>
          ))}
        </div>

        {/* Date Filter */}
        <div className="flex gap-4 items-center">
          <div>
            <label className="block text-sm font-medium mb-1">Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border rounded p-2 w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border rounded p-2 w-full"
            />
          </div>
        </div>
      </div>

      {/* Subscriptions */}
      <div className="space-y-4">
        {filteredSubscriptions.length > 0 ? (
          filteredSubscriptions.map((subscription) => (
            <div
              key={subscription.id}
              className="border rounded-lg p-4 shadow-sm bg-white text-gray-800"
            >
              <div className="text-sm space-y-2">
                <p>
                  <strong>Amount:</strong> ₹{subscription.amount}
                </p>
                <p>
                  <strong>Email:</strong> {subscription.email}
                </p>
                <p>
                  <strong>UPI:</strong> {subscription.upi}
                </p>
                <p
                  className={`font-bold ${
                    subscription.status.toUpperCase() === "ACTIVE"
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  <strong>Status:</strong> {subscription.status}
                </p>
                <p>
                  <strong>Transaction ID:</strong> {subscription.transactionId}
                </p>
                <p>
                  <strong>Subscription ID:</strong>{" "}
                  {subscription.subscriptionId}
                </p>
                <p>
                  <strong>Recurring Type:</strong> {subscription.recurring_type}
                </p>
                <p>
                  <strong>Start Date:</strong>{" "}
                  {new Date(subscription.start_date).toLocaleDateString()}
                </p>
                <p>
                  <strong>End Date:</strong>{" "}
                  {new Date(subscription.end_date).toLocaleDateString()}
                </p>
                <button
                  onClick={() => handleDeleteClick(subscription.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">No subscriptions found.</p>
        )}
      </div>

      {deleteError && (
        <p className="text-center text-red-500 mt-4">{deleteError}</p>
      )}

      {/* Navigation */}
      <div className="flex justify-around fixed bottom-0 left-0 w-full bg-yellow-500 p-2">
        <Link to="/user/dashboard" className="flex flex-col items-center text-white">
          <RiArrowGoBackFill className="h-6 w-6" />
          <span className="text-xs">Back</span>
        </Link>
        <Link to="/user/store" className="flex flex-col items-center text-white">
          <RiStore3Fill className="h-6 w-6" />
          <span className="text-xs">Store</span>
        </Link>
      </div>
    </div>
  );
};

export default SubscriptionList;
