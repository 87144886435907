import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const StatusCheck = () => {
  const { state } = useLocation();
  const { insertId } = state; // Only need insertId for display now
  const navigate = useNavigate();
  const [timer, setTimer] = useState(300); // Countdown timer (300 seconds = 5 minutes)
  const [status, setStatus] = useState(null); // Keep status null for now, since there's no API

  useEffect(() => {
    if (timer === 0) {
      // Here you can define the behavior when the countdown finishes
      setStatus("PENDING"); // Example, change status when countdown ends
      setTimeout(() => navigate("/Dashboard"), 2000); // Redirect to Dashboard after 2 seconds
    } else {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown); // Cleanup on unmount or rerender
    }
  }, [timer, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full p-4 bg-white rounded-lg shadow-lg text-center">
        <h4 className="text-2xl font-bold mb-4">Checking Payment Status</h4>
        <h1>Your Order id : {insertId}</h1>
        <p className="text-gray-700 mb-4">
          Please wait... {timer} seconds remaining
        </p>
        {status && (
          <p
            className={`text-lg font-bold ${
              status === "SUCCESS" ? "text-green-500" : "text-red-500"
            }`}
          >
            {status === "SUCCESS"
              ? "Payment Successful"
              : status === "FAILED"
              ? "Payment Failed"
              : "Payment Pending"}
          </p>
        )}
      </div>
    </div>
  );
};

export default StatusCheck;
