import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/GlobalProvider";
import { GOLD_LIVE_PRICE, USER_CURRENCY_ANTS } from "../../../hooks/APIHooks";

import {
  Goldraw,
  GoldSmithCover,
  GoldBg,
  goldsmith_title,
} from "../../../assets/data/Imagedata";
import { useLocation } from "react-router-dom";

function GoldCoins() {
  const { user } = useAuth();
  const [referralCode, setReferralCode] = useState(user.referralsignup || "");
  const [currency, setCurrency] = useState(null);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [goldPrice, setGoldPrice] = useState(null);
  const goldpricelive = GOLD_LIVE_PRICE;
  const [loading, setLoading] = useState(true);

  const handleReferralCodeChange = (e) => {
    setReferralCode(e.target.value); // Update state when the input changes
  };

  useEffect(() => {
    const fetchGoldPrice = async () => {
      try {
        const response = await fetch(goldpricelive);
        const data = await response.json();

        if (data && data.length > 0) {
          const goldData = data.find((item) => item.product_name === "Gold");
          if (goldData) {
            setGoldPrice(goldData.price);
          }
        }
      } catch (error) {
        console.error("Error fetching gold price:", error);
      }
    };

    fetchGoldPrice();
  }, [goldpricelive]);

  const fetchCurrency = async (email) => {
    try {
      const response = await axios.get(
        `${USER_CURRENCY_ANTS}?emailaddress=${email}`
      );
      setCurrency(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          "https://batchugold.com/apis/Store/ProductPost.php"
        );
        const data = await response.json();

        // Filter products by product_catalogue and title
        const filteredData = data.filter(
          (product) => product.product_catalogue === "Gold Coin"
        );

        setProducts(filteredData);
        setFilteredProducts(filteredData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const calculateTotalPrice = (product) => {
    if (!goldPrice) return "Loading...";

    const goldPricePerGram = goldPrice / 1;
    const originalProductPrice = goldPricePerGram * product.weight;

    const makingCharges =
      (product.making_percentage / 100) * originalProductPrice;
    const gst = 0.03 * (originalProductPrice + makingCharges);

    const totalPrice = originalProductPrice + makingCharges + gst;

    return `${totalPrice.toFixed(0)}`; // Changed toFixed(0) to remove decimals
  };

  const buyNow = async () => {
    if (!selectedProduct) return;

    const totalPrice = calculateTotalPrice(selectedProduct);
    const totalPriceInt = parseInt(totalPrice, 10);

    if (currency && currency.gold_grams >= selectedProduct.weight) {
      // Calculate new gold_grams after deduction
      const newGoldGrams = currency.gold_grams - selectedProduct.weight;

      // Deduct points from user's account
      try {
        const deductPointsResponse = await axios.put(`${USER_CURRENCY_ANTS}`, {
          id: currency.id,
          gold_grams: newGoldGrams,
          remarks: `Purchased ${selectedProduct.title}`,
        });

        if (deductPointsResponse.data.status === "success") {
          // Update local state for currency
          setCurrency((prevCurrency) => ({
            ...prevCurrency,
            gold_grams: newGoldGrams,
          }));

          // Generate random number
          const randomNum = Math.floor(Math.random() * 1000000); // Adjust as needed

          // Now proceed to place the order
          try {
            const orderResponse = await axios.post(
              "https://batchugold.com/apis/Store/GoldSmithOrderPlace.php",
              {
                merchantTransactionId: `${user.name}-${randomNum}`,
                merchantUserId: user.emailaddress,
                amount: totalPriceInt,
                merchantOrderId: user.name,
                mobileNumber: user.phonenumber,
                message: "Order From Store | Address:" + user.address,
                email: user.emailaddress,
                shortName: "BAT_GoldSmith",
                orderlist: `${selectedProduct.title}`,
                delivery: "PROCESSING",
                quantity: 1,
                referralCode: referralCode,
              }
            );

            if (orderResponse.data.status === "success") {
              alert("Purchase successful!");
            } else {
              const errorMessage =
                orderResponse.data.message || "Unknown error occurred";
              console.error("Order Response Error:", orderResponse.data);
              alert("Purchase failed: " + errorMessage);
            }
          } catch (error) {
            console.error("Order Placement Error:", error);
            alert("Error placing order: " + error.message);
          }
        } else {
          console.error("Deduct Points Error:", deductPointsResponse.data);
          alert("Failed to deduct points.");
        }
      } catch (error) {
        console.error("Deduct Points API Error:", error);
        alert("Error deducting points: " + error.message);
      }
    } else {
      alert("Insufficient gold grams to make the purchase.");
    }
  };

  useEffect(() => {
    if (!user || !user.emailaddress) return;
    setLoading(true);
    fetchCurrency(user.emailaddress);
  }, [user, location]);

  return (
    <section
      className=" py-16 items-center justify-center overflow-hidden"
      style={{
        backgroundImage: `url("${GoldSmithCover}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
      }}
    >
      <section className="">
        <img
          title="goldsmith"
          src={goldsmith_title}
          alt="Gold"
          className="md:w-[50%]  h-auto mx-auto m-4"
        />

        <p className="text-[18px] text-center font-bold  text-white ">
          Convert your gold nuggets into Gold Coins
        </p>
        <p className="text-[16px] text-center text-white ">
          Get 100% Referral Commission on every purchase.
        </p>

        <div>
          <img
            src={Goldraw}
            alt="Gold"
            className="md:w-[10%] w-[30%] h-auto mx-auto m-4"
          />
        </div>

        <div className="flex w-full items-center justify-center">
          <div className="relative z-10 text-right">
            {currency ? (
              <div className=" text-center">
                <p className="text-[13px] text-white bg-yellow-500 px-2 rounded-full ">
                  Gold Coin Grams Available
                </p>
                <p className="text-white text-[20px] font-bold">
                  {currency.gold_grams}
                </p>
              </div>
            ) : (
              <p className="text-[10px] text-white">0 Earn</p>
            )}
          </div>
        </div>

        <div className=" mx-auto flex">
          <div className="w-full grid grid-cols-3">
            {filteredProducts.map((product) => (
              <div
                key={product.id}
                className={`bg-yellow-900 rounded-lg shadow-md overflow-hidden mx-2 m-3 py-2 ${
                  selectedProduct && selectedProduct.id === product.id
                    ? "border-2 border-white"
                    : ""
                }`}
                style={{
                  backgroundImage: `url("${GoldBg}")`,
                  backgroundSize: "contain",
                  backgroundPosition: "top",
                }}
                onClick={() => handleProductSelect(product)}
              >
                <div className=" text-center p-2 ">
                  <h3 className="text-[12px] font-semibold ">
                    {product.title}
                  </h3>
                  <p className="text-[8px] text-gray-900 leading-tight">
                    Purity: 24 Karat (99.9)
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {selectedProduct && (
          <div className="flex z-50 fixed w-full bottom-0 bg-white h-[70px] drop-shadow-xl">
            <div className="w-1/2 flex items-center justify-center">
              <label className="block p-2 text-gray-700 text-[12px] font-black">
                Referral Code:
                <input
                  type="text"
                  value={referralCode} // Bind input value to state
                  placeholder="Enter referral code"
                  onChange={handleReferralCodeChange} // Update state on change
                  className="w-full px-2 py-2 border rounded"
                />
              </label>
            </div>

            <button
              className="w-1/2 flex items-center bg-yellow-500 hover:bg-orange-600 transition duration-300 justify-center"
              style={{
                backgroundImage: `url("${GoldBg}")`,
                backgroundSize: "contain",
                backgroundPosition: "top",
              }}
              onClick={buyNow}
            >
              <div className="flex items-center justify-center text-black text-[20px]  font-bold ">
                Convert & Buy
              </div>
            </button>
          </div>
        )}
      </section>
    </section>
  );
}

export default GoldCoins;
