import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/GlobalProvider";
import { MONTHLY_SUBSCRIPTION } from "../../../hooks/APIHooks";
import { Link } from "react-router-dom";
import { RiArrowGoBackFill, RiStore3Fill } from "react-icons/ri";

const SubscriptionComponent = () => {
  const { user } = useAuth();
  const [amount, setAmount] = useState(); // Default amount
  const [phone, setPhone] = useState(user.phonenumber);
  const [userId, setUserId] = useState(1);
  const [email, setEmail] = useState(user.emailaddress);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubscription = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const result = await axios.post(MONTHLY_SUBSCRIPTION, {
        subscribe: true,
        amount: amount, // Use user input
        plan_id: "3", // Replace with actual plan ID
        phone: phone, // Use user input
        user_id: userId, // Use user input
        email: email, // Use user input
        recurring_type: "MONTHLY",
      });

      setResponse(result.data);
      setError(null); // Reset error state

      // Check for successful subscription
      if (result.data.insert_id) {
        // If insert_id is returned, use it for navigation
        const insertId = result.data.insert_id;
        // Also, ensure that the subscriptionId is correctly extracted
        const subscriptionId = result.data.subscriptionId; // Adjust if the key is different

        navigate(
          `/MarketPlace/Monthly/verifyupi?insert_id=${insertId}&phonenumber=${phone}&emailaddress=${email}&amount=${amount}&subscriptionId=${subscriptionId}`
        );
      }
    } catch (err) {
      setError(err.response.data || "An error occurred");
      setResponse(null); // Reset response state
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full p-4 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4 text-center">
       Save Gold Monthly 
        </h2>
        <form onSubmit={handleSubscription}>
          <div className="mb-4">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Amount
              </label>
              <input
                type="text"
                value={amount}
                readOnly
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"
              />
            </div>
            <label className="block text-sm font-medium text-gray-700">
              Select Amount
            </label>
            <div className="flex flex-wrap space-x-2 mt-2 text-[12px]">
              {[1000, 2000, 5000, 10000].map((val) => (
                <button
                  key={val}
                  type="button"
                  onClick={() => setAmount(val)}
                  className="py-2 px-4 bg-yellow-600 text-white font-semibold rounded-md hover:bg-yellow-700 transition duration-200 mb-2"
                >
                  ₹{val}
                </button>
              ))}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Phone
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                readOnly
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"
              />
            </label>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"
              />
            </label>
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-yellow-600 text-white font-semibold rounded-md shadow hover:bg-yellow-700 transition duration-200"
          >
            Subscribe
          </button>
        </form>

        {response && (
          <div className="mt-4 p-4 border border-green-500 bg-green-100 rounded-md">
            <h3 className="font-semibold text-green-800">
              Status: {response.status}
            </h3>
            <p>Message: {response.message}</p>
            <p>Insert ID: {response.insert_id}</p>
          </div>
        )}
        {error && (
          <div className="mt-4 p-4 border border-red-500 bg-red-100 rounded-md">
            <h3 className="font-semibold text-red-800">Error:</h3>
            <p>{error}</p>
          </div>
        )}
      </div>
       {/* Navigation */}
       <div className="flex justify-around fixed bottom-0 left-0 w-full bg-yellow-500 p-2">
        <Link
          to="/MarketPlace"
          className="flex flex-col items-center text-white"
        >
          <RiArrowGoBackFill className="h-6 w-6" />
          <span className="text-xs">Back</span>
        </Link>
        <Link to="/" className="flex flex-col items-center text-white">
          <RiStore3Fill className="h-6 w-6" />
          <span className="text-xs">Home</span>
        </Link>
      </div>
    </div>
  );
};

export default SubscriptionComponent;
