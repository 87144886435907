import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/GlobalProvider";
import { GoldCoin_BAT } from "../../assets/data/Imagedata";
import {
  FaHistory,
  FaCoins,
  FaStore,
  FaShoppingCart,
  FaUserEdit,
  FaLifeRing,
  FaMoneyBillWave,
  FaBug,
} from "react-icons/fa";
import { IoIosContacts } from "react-icons/io";
import { MdSubscriptions } from "react-icons/md";
import { MdOutlineSubscriptions } from "react-icons/md";

function Dashboard() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate("/signin");
  };

  return (
    <section className="py-20 sm:py-24 px-5">
      <div className="flex justify-center ">
        {/* Center Column */}
        <div className="lg:w-1/3 w-full">
          {/* Only show Dashboard Actions if the user is not an admin */}
          {user && user.role !== "admin" && (
            <div className="flex-1 flex flex-col items-center py-10 bg-white rounded-2xl shadow-2xl">
              <section>
                <div className="max-w-full flex flex-col items-center justify-center rounded-2xl p-6 text-black">
                  {user ? (
                    <>
                      <div className="flex flex-col items-center ">
                        <img
                          src={GoldCoin_BAT}
                          alt="GoldCoin_BAT profile"
                          width={100}
                          height={100}
                          className="rounded-full"
                        />
                        <span className="font-bold text-lg">
                          Hello, {user.name}
                        </span>
                        {user.emailaddress}
                        {user.is_active === 1 || user.is_active === "1" ? (
                          <span className="text-white bg-blue-500 rounded-full text-[10px] px-2">
                            VERIFIED
                          </span>
                        ) : (
                          <span className="text-white bg-black rounded-full text-[10px] px-2">
                            NOT VERIFIED
                          </span>
                        )}
                        <div className="flex-row text-center">
                          <span className="text-[10px]">
                            {user.phonenumber}
                          </span>
                          <br />
                          <span className="text-[10px]">{user.address}</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <span className="text-black">Welcome Guest</span>
                  )}
                </div>
              </section>

              <div className="flex flex-col items-center justify-center w-full px-10">
                <div className="w-full grid grid-cols-1 gap-4 ">
                  <div className="border-t border-gray-300 " />

                  <div className="text-center ">
                    <p className="text-xl font-medium">Transactions</p>
                    <p className="text-sm">Check all your transactions</p>
                  </div>

                  <a href="/user/paymenthistory">
                    <button className="flex items-center justify-center text-black py-2">
                      <FaMoneyBillWave className="mr-3" size={23} />
                      <p className="text-[16px]">Payment History</p>
                    </button>
                    <span className="text-xs text-gray-600">
                      View your payment transactions and remaining balance.
                    </span>
                  </a>
                  <div className="border-t border-gray-300 "></div>
                  <div className="text-center">
                    <p className="text-xl font-medium">Personal</p>
                    <p className="text-sm">Modify your profile</p>
                  </div>

                  <Link to="/user/profileedit">
                    <button className="flex items-center justify-center text-black py-2">
                      <FaUserEdit className="mr-3" size={23} />
                      <p className="text-[16px]">Edit Profile</p>
                    </button>
                    <span className="text-xs text-gray-600">
                      Change your details. You cannot change your emailaddress,
                      please contact support.
                    </span>
                  </Link>

                  <div className="border-t border-gray-300 "></div>

                  <div className="text-center">
                    <p className="text-xl font-medium">Help Center</p>
                    <p className="text-sm">Need more help?</p>
                  </div>

                  <Link to="/contactus">
                    <button className="flex items-center justify-center text-black py-2">
                      <FaLifeRing className="mr-3" size={23} />
                      <p className="text-[16px]">Contact Us</p>
                    </button>
                    <span className="text-xs text-gray-600">
                      We are here to help you 24/7.
                    </span>
                  </Link>

                  <Link to="/contactus">
                    <button className="flex items-center justify-center text-black py-2">
                      <FaBug className="mr-3" size={23} />
                      <p className="text-[16px]">Report a Bug</p>
                    </button>
                    <span className="text-xs text-gray-600">
                      Having issues? Let us know so we can resolve them.
                    </span>
                  </Link>

                  <button
                    onClick={handleLogout}
                    className="w-full bg-red-500 text-white py-3 rounded-lg hover:bg-red-600 transition duration-300"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Admin controls */}
          {user && user.role === "admin" && (
            <div className=" w-full text-black text-center rounded-md mt-10 p-2">
              <div>
                <div className="text-center mb-4">
                  <p className="text-xl font-medium">Admin Controls</p>
                  <p className="text-sm">Only Admin can access this.</p>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <Link to="/Admin/ProductPost">
                    <button className="flex items-center justify-center w-full border rounded-lg bg-yellow-500 hover:bg-yellow-700">
                      <FaShoppingCart size={18} className="m-2 my-5" />
                      <p className="text-[14px] font-bold">Post Products</p>
                    </button>
                  </Link>

                  <Link to="/Admin/LiveGoldPriceChange">
                    <button className="flex items-center justify-center w-full border rounded-lg bg-yellow-500 hover:bg-yellow-700">
                      <FaHistory size={18} className="m-2 my-5" />
                      <p className="text-[14px] font-bold">Live Gold Price</p>
                    </button>
                  </Link>
                </div>

                <div className="text-center my-4">
                  <p className="text-xl font-medium">Transations</p>
                  <p className="text-sm">All Transation and Payments.</p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <Link to="/Admin/AllPaymentTransation">
                    <button className="flex items-center justify-center w-full border rounded-lg bg-yellow-500 hover:bg-yellow-700">
                      <FaMoneyBillWave size={18} className="m-2 my-5" />
                      <p className="text-[14px] font-bold">All Transactions</p>
                    </button>
                  </Link>
                  <Link to="/Admin/AllStoreHistory">
                    <button className="flex items-center justify-center w-full border rounded-lg bg-yellow-500 hover:bg-yellow-700">
                      <FaStore size={18} className="m-2 my-5" />
                      <p className="text-[14px] font-bold">All Store History</p>
                    </button>
                  </Link>

                  <Link to="/Admin/AllReferralHistory">
                    <button className="flex items-center justify-center w-full border rounded-lg bg-yellow-500 hover:bg-yellow-700">
                      <FaMoneyBillWave size={18} className="m-2 my-5" />
                      <p className="text-[14px] font-bold">
                        Referral Store History
                      </p>
                    </button>
                  </Link>

                  <Link to="/Admin/AllGoldSmithHistory">
                    <button className="flex items-center justify-center w-full border rounded-lg bg-yellow-500 hover:bg-yellow-700">
                      <FaCoins size={18} className="m-2 my-5" />
                      <p className="text-[14px] font-bold">GoldSmith History</p>
                    </button>
                  </Link>
                </div>

                <div className="text-center my-4">
                  <p className="text-xl font-medium">Subscriptions</p>
                  <p className="text-sm">
                    All Subscriptions and Subscriptions Transations.
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <Link to="/Admin/AllSubscriptions">
                    <button className="flex items-center justify-center w-full border rounded-lg bg-yellow-500 hover:bg-yellow-700">
                      <MdSubscriptions size={18} className="m-2 my-5" />
                      <p className="text-[14px] font-bold">Subscriptions</p>
                    </button>
                  </Link>

                  <Link to="/Admin/AllSubscriptionsTransations">
                    <button className="flex items-center justify-center w-full border rounded-lg bg-yellow-500 hover:bg-yellow-700">
                      <MdOutlineSubscriptions size={18} className="m-2 my-5" />
                      <p className="text-[14px] font-bold">
                        Subscription History
                      </p>
                    </button>
                  </Link>
                </div>

                <div className="text-center my-4">
                  <p className="text-xl font-medium">Users</p>
                  <p className="text-sm">All Users and wallets.</p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <Link to="/Admin/AllUsers">
                    <button className="flex items-center justify-center w-full border rounded-lg bg-yellow-500 hover:bg-yellow-700">
                      <IoIosContacts size={18} className="m-2 my-5" />
                      <p className="text-[14px] font-bold">All Users</p>
                    </button>
                  </Link>
                </div>
              </div>

              <button
                onClick={handleLogout}
                className="mt-5 w-full bg-red-500 text-white py-3 rounded-lg hover:bg-red-600 transition duration-300"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
