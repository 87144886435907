import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../../../hooks/GlobalProvider";
import { MONTHLY_SUBSCRIPTION_SUBMITAUTH } from "../../../hooks/APIHooks";

const SubmitAuthReq = () => {
  const [upi, setUPI] = useState("");
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [userId, setUserId] = useState(user.phonenumber);

  const [searchParams] = useSearchParams();
  const insertId = searchParams.get("insert_id");
  const subscriptionId = searchParams.get("subscriptionId");
  const amountParam = searchParams.get("amount");

  const navigate = useNavigate();

  const handleVerifyUPI = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setMessage("");

    // Log input values for debugging
    console.log("Insert ID:", insertId, "UPI ID:", upi);

    const payload = {
      insert_id: insertId,
      upi_id: upi,
      user_id: userId,
    };

    try {
      const response = await axios.post(MONTHLY_SUBSCRIPTION_SUBMITAUTH, payload);

      if (response.data.status === "success") {
        setMessage(
          "UPI request initiated successfully. Please check your UPI app for further instructions."
        );

        // Redirect after a short delay
        setTimeout(() => {
          navigate("/MarketPlace");
        }, 3000);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      // Log error response for debugging
      console.error("API Error:", err.response || err);
      setError("Please check you payment gateway.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full p-4 bg-white rounded-lg shadow-lg">
        <form onSubmit={handleVerifyUPI}>
          <h4 className="text-2xl font-bold mb-4 text-center">
            Step 3 - Initiate Payment via UPI
          </h4>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Subscribe ID
            </label>
            <input
              type="text"
              value={subscriptionId}
              placeholder="Subscription ID"
              readOnly
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Amount
            </label>
            <input
              type="text"
              value={amountParam}
              placeholder="Amount"
              readOnly
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-200"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              UPI ID:
            </label>
            <input
              type="text"
              value={upi}
              onChange={(e) => setUPI(e.target.value)}
              placeholder="Enter UPI ID"
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 px-4 bg-yellow-600 text-white font-semibold rounded-md shadow hover:bg-yellow-700 transition duration-200"
          >
            {loading ? "Sending Request..." : "Send Payment Request"}
          </button>

          {message && (
            <div className="mt-4 p-4 border border-green-500 bg-green-100 rounded-md">
              <p style={{ color: "green" }}>{message}</p>
            </div>
          )}
          {error && (
            <div className="mt-4 p-4 border border-red-500 bg-red-100 rounded-md">
              <p style={{ color: "red" }}>{error}</p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default SubmitAuthReq;
