import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/GlobalProvider";
import { RiStore2Line, RiHome2Line, RiAccountCircleLine } from "react-icons/ri";
import { GiAnt, GiGoldBar } from "react-icons/gi";

function BottomNavbar() {
  const location = useLocation();
  const [circlePos, setCirclePos] = useState({ left: 0 });
  const navRef = useRef();
  const { user } = useAuth();

  useEffect(() => {
    const navElement = navRef.current;
    if (navElement) {
      const activeLink = navElement.querySelector(".active");
      if (activeLink) {
        const rect = activeLink.getBoundingClientRect();
        const navRect = navElement.getBoundingClientRect();
        setCirclePos({ left: rect.left - navRect.left + rect.width / 2 - 20 });
      }
    }
  }, [location.pathname]);

  const isVisible = [
    "/Store",
    "/contactus",
    "/MarketPlace",
    "/",
    "/aboutus",
    "/user/dashboard",
    "/user/currency",
    "/signup",
    "/signin",
    "/forgot-password",
  ].includes(location.pathname);

  if (!isVisible) {
    return null;
  }

  const NavItem = ({ to, icon: Icon, label }) => {
    const isActive = location.pathname === to;
    return (
      <div className="flex flex-col items-center group">
        <Link
          to={to}
          className={`flex flex-col items-center px-4 rounded-full ${
            isActive
              ? "active text-white"
              : "text-black group-hover:text-yellow-500"
          }`}
        >
          <Icon
            className={`${
              isActive
                ? "text-yellow-500 z-10 text-[28px]"
                : "text-black z-10 text-[24px]"
            }`}
          />
          <span className="text-[10px] text-black">{label}</span>
        </Link>
      </div>
    );
  };

  return (
    <nav className="fixed bottom-0 w-full z-[100] drop-shadow-2xl">
      <div className="flex justify-center relative">
        <div
          ref={navRef}
          className="flex flex-row justify-around w-full sm:max-w-md py-2 px-2 border-t-4 border-white bg-white sm:rounded-xl relative z-10"
        >
          <NavItem to="/Store" icon={RiStore2Line} label="Store" />
          <NavItem to="/MarketPlace" icon={GiGoldBar} label="Market" />
          <NavItem to="/" icon={RiHome2Line} label="Home" />
          <NavItem to="/aboutus" icon={GiAnt} label="About" />
          {user ? (
            <NavItem
              to="/user/dashboard"
              icon={RiAccountCircleLine}
              label="Account"
            />
          ) : (
            <NavItem to="/signup" icon={RiAccountCircleLine} label="Signup" />
          )}
        </div>
      </div>
    </nav>
  );
}

export default BottomNavbar;
